<template>
	<div>
		<div class="ordermanagebox">

			<el-card>
				<div class="global-search-box">
					<div class="search-content">
						<div class="search-item">
							<el-input :placeholder="orderref == 1 ? '请输入订单编号' : '请输入政采编号'" v-model="orderefName">
								<template slot="prepend">
									<el-select v-model="orderref" placeholder="请选择">
										<el-option v-for="(item, index) in ordersearchList" :key="item.value"
											:label="item.label" :value="item.value"></el-option>
									</el-select>
								</template>
							</el-input>
						</div>
						<div class="search-item">
							<el-input :placeholder="shopnamesku == 1 ? '请输入商品名称' : '请输入商品sku'" v-model="ordershopname">
								<template slot="prepend">
									<el-select v-model="shopnamesku" placeholder="请选择">
										<el-option v-for="(item, index) in ordershopList" :key="item.value"
											:label="item.label" :value="item.value"></el-option>
									</el-select>
								</template>
							</el-input>
						</div>
						<div class="search-item">
							<el-input
								:placeholder="valuesecond == 1 ? '请输入收货人姓名' : valuesecond == 2 ? '请输入收货人电话' : valuesecond == 3 ? '请输入发票抬头' : ''"
								v-model="valuename">
								<template slot="prepend">
									<el-select v-model="valuesecond" placeholder="请选择">
										<el-option v-for="(item, index) in valuesecondlist" :key="item.value"
											:label="item.label" :value="item.value"></el-option>
									</el-select>
								</template>
							</el-input>
						</div>
						<div class="search-item">
							<label class="search-label">订单类型：</label>
							<el-select v-model="ordertype" filterable clearable placeholder="请选择">
								<el-option v-for="(item, index) in common_info['order.type.list']" :key="item.value"
									:label="item.value" :value="item.key"></el-option>
							</el-select>
						</div>
						<div class="search-item">
							<label class="search-label">订单来源：</label>
							<!-- <el-select v-model="ordersource" filterable clearable placeholder="请选择"
								@change="addhandleChange">
								<el-option v-for="(item, index) in common_info['order.source.type.list']" :key="index"
									:label="item.value" :value="item.key" :type="item.type">
								</el-option>
							</el-select> -->
							<el-cascader v-model="ordersource" :props="{ checkStrictly: true }"
								:options="common_info['order.source.list']" @change="addhandleChange">
							</el-cascader>
						</div>
						<div class="search-item">
							<label class="search-label">商品类型：</label>
							<el-select v-model="shoptype" clearable placeholder="请选择">
								<el-option v-for="(item, index) in common_info['goods.vr_type.list']" :key="index"
									:label="item.value" :value="item.key"></el-option>
							</el-select>
						</div>
						<template v-if="is_filter">
							<div class="picker-date-picker">
								<el-select v-model="valuetime" placeholder="请选择" @change="ordertimechange">
									<el-option v-for="(item, index) in timeList" :key="item.value" :label="item.label"
										:value="item.value"></el-option>
								</el-select>
								<el-date-picker v-model="valuetimes" type="datetimerange" start-placeholder="开始日期"
									format="yyyy-MM-dd" end-placeholder="结束日期" :picker-options="pickerOptions"
									:default-time="['00:00:00', '23:59:59']">
								</el-date-picker>
							</div>
							<div class="search-item">
								<label class="search-label">售后状态：</label>
								<el-select v-model="servicestatus" clearable placeholder="请选择">
									<el-option v-for="(item, index) in  common_info['order.refund.status.list']"
										:key="item.key" :label="item.value" :value="item.key"></el-option>
								</el-select>
							</div>
							<div class="search-item">
								<label class="search-label">订单状态：</label>
								<el-select v-model="orderstatus" clearable placeholder="请选择">
									<el-option v-for="(item, index) in  common_info['order.status.list']" :key="item.key"
										:label="item.value" :value="item.key"></el-option>
								</el-select>
							</div>
							<div class="search-item">
								<label class="search-label">配送方式：</label>
								<el-select v-model="shipping" clearable placeholder="请选择">
									<el-option v-for="(item, index) in shippingList" :key="item.value" :label="item.label"
										:value="item.value"></el-option>
								</el-select>
							</div>
							<div class="search-item">
								<label class="search-label">支付方式：</label>
								<el-select v-model="paytype" clearable placeholder="请选择">
									<el-option v-for="(item, index) in common_info['order.payment.type.list']" :key="index"
										:label="item.value" :value="item.key"></el-option>
								</el-select>
							</div>
							<div class="search-item">
								<label class="search-label">员工账号：</label>
								<el-input v-model="account_number" placeholder="请输入员工账号" clearable></el-input>
							</div>
							<div class="search-item">
								<label class="search-label">省市区：</label>
								<div class="city-province">
									<el-select v-model="save" placeholder="省级" @change="choseProvince">
										<el-option v-for="(item, index) in citylisted" :key="index" :label="item.name"
											:value="item.id"></el-option>
									</el-select>
									<el-select v-model="cname" @change="choseCity" placeholder="市级">
										<el-option v-for="(item, $index) in city" :key="$index" :label="item.value"
											:value="item.id"></el-option>
									</el-select>
									<el-select v-model="bname" @change="choseBlock" placeholder="区级">
										<el-option v-for="(item, $index) in block" :key="$index" :label="item.value"
											:value="item.value"></el-option>
									</el-select>
								</div>
							</div>
							<!-- <div class="search-item">
								<label class="search-label">会员等级：</label>
								<el-select v-model="vipgrade" clearable placeholder="请选择">
									<el-option v-for="(item, index) in common_info['user.level_list']" :key="index"
										:label="item.name" :value="item.level"></el-option>
								</el-select>
							</div> -->
						</template>
						<div class="search-item">
							<div class="diy-search--more" @click="filtertab">
								<span>高级筛选</span>
								<i class="el-icon-arrow-down" v-if="!is_filter"></i>
								<i class="el-icon-arrow-up" v-else></i>
							</div>
						</div>
					</div>
					<div class="serach-buttom-button">
						<el-button type="primary" @click="ordersearched">搜索</el-button>
						<el-button @click="resetting">重置条件</el-button>
						<el-button @click="exportTable">导出报表</el-button>
					</div>

				</div>
			</el-card>

			<div class="ordermanageboxbut">
				<div class="ordermanagenav ordermanagenavadd">
					<div class="ordermanagenavleft d-flex">
						<el-tabs v-model="activeName" @tab-click="handleClick">
							<el-tab-pane label="全部"></el-tab-pane>
							<template v-if="common_info['order.status.list'] && common_info['order.status.list'].length">
								<el-tab-pane v-for="(item, index) in  common_info['order.status.list']" :key="item.key"
									:label="item.value"></el-tab-pane>
							</template>
						</el-tabs>
					</div>
					<div class="ordermanagenavright d-flex">
						<div class="manageleft d-flex">
							<span>排序：</span>
							<div class="orderselect mangeselect">
								<template>
									<el-select v-model="ordersort" placeholder="请选择" @change="timechange">
										<el-option v-for="(item, index) in timeList" :key="item.value" :label="item.label"
											:value="item.value"></el-option>
									</el-select>
								</template>
							</div>
						</div>
						<div class="manageright d-flex">
							<span :class="[sortIndex == 1 ? 'active' : '']" @click="ordersorted(1)">正序 <i
									class="el-icon-top"></i></span>
							<span :class="[sortIndex == 2 ? 'active' : '']" @click="ordersorted(2)">倒序 <i
									class="el-icon-bottom"></i></span>
						</div>
					</div>
				</div>
				<div v-if="tableData && tableData.length">
					<div class="ordertabel">
						<div class="ordertabeltitle clearfloat">
							<p>商品信息</p>
							<p>金额</p>
							<p>实际支付</p>
							<p>订单状态</p>
							<p>操作</p>
						</div>
						<div class="ordertabelcon">
							<div class="ordertabelist" v-for="(item, index) in tableData" :key="index">
								<div class="ordertabelistup clearfloat">
									<div class="ordertabelistleft">
										<!-- <p class="orderputong">{{ common_info["order.type.map"][item.type] }}</p> -->
										<p>
											<strong>订单来源：</strong>
											<!-- {{ common_info['order.source.type.map'][item.order_source] }} -->
											<!-- <i><img src="../../assets/images/cs1.png" alt=""></i> -->
											{{ item.company_category_title }}
										</p>
										<p>
											<strong>订单编号：</strong>{{ item.order_no }}
										</p>
										<p><strong>下单时间：</strong>{{ item.created_at }}</p>
										<p><strong>员工账号：</strong>{{ item.purchaser_name }}</p>

									</div>
									<div class="ordertabelistright clearfloat">
										<!-- <a href="javascript:void(0);">打印订单</a> -->
										<a href="javascript:void(0);" @click="loopdetail(item.id)"
											v-if="hasPerm(['order.show'])">查看详情</a>
										<a href="javascript:void(0);" @click="attributed(item)"
											v-if="hasPerm(['order.update.remark'])">备注 <i v-if="item.words"></i></a>
										<a href="javascript:void(0);" @click="orderlog(item.id)">日志</a>
										<!-- <a href="javascript:void(0);">查看回执单</a> -->
										<a href="javascript:void(0);" @click.stop="delOrder(item)">删除订单</a>
									</div>
								</div>
								<div class="ordertabelistcon d-flex">
									<div class="order_item">
										<div class="order_itemlist clearfloat"
											v-for="(itemed, indexed) in item.order_detail" :key="indexed"
											v-if="indexed < 3">
											<div class="order_itemimg">
												<img :src="itemed.pic" alt="" />
											</div>
											<div class="order_itemtext">
												<a href="javascript:void(0);">{{ itemed.goods_name }}</a>
												<div class="orderguige clearfloat">
													<p v-for="(orderitem, orderindex) in itemed.attr">
														<span>{{ orderitem.specs_key }}：</span>{{ orderitem.specs_value }}
													</p>
												</div>
												<div class="orderbutlast">
													<p><span>单价：</span>{{ itemed.price }}</p>
													<p><span>数量：</span>{{ itemed.num }}</p>
													<!-- <p><span>会员折扣：</span>{{ item.discount }}折</p> -->
												</div>
											</div>
										</div>

										<div class="order_itembuts">
											<span>共 <strong>{{ item.detail_goods_count }}</strong> 种商品</span>
											<a href="javascript:void(0);" v-if="item.detail_goods_count >= 3"
												@click="loopdetail(item.id)">查看更多商品<i class="el-icon-arrow-right"></i></a>
										</div>
									</div>
									<div class="order_item d-flex align-items-center">
										<div class="order_itemoney">
											<p class="ormoney">{{ item.total_price }}</p>
											<p class="orderfare">运费：{{ item.express_price }}</p>
											<!-- <p class="viprebate">会员折扣：<span>{{ item.discount }}</span>折</p> -->
										</div>
									</div>
									<div class="order_item d-flex align-items-center">
										<div class="realitymoney">
											<!-- <p>{{ item.pay_price }}</p> -->
											<p v-if="Number(item.account_price) > 0">积分：{{ item.account_price }}积分</p>
											<p v-if="Number(item.money_price) > 0">现金：{{ item.money_price }}元</p>
										</div>
									</div>
									<div class="order_item d-flex align-items-center justify-content-center">
										<div>
											<div class="orderstatused">
												<p v-for="(ites, ins) in item.status" :key="ins">{{ ites }}</p>
											</div>
											<div class="order_itemstatus">
												<p class="orderpays">{{
													common_info["order.payment.pay_type.map"][item.pay_type2] }}</p>
												<span class="ziti" v-if="item.is_offline == 1">自提</span>
												<span class="kuaidi" v-else>快递</span>

												<!-- <span class="kami" style="display: none">卡</span>
												<span class="xuni" style="display: none">虚</span> -->
											</div>
										</div>
									</div>
									<div class="order_item d-flex align-items-center">
										<div class="order_operate">
											<div v-if="item.apply_delete == 1">
												<p v-if="item.is_revoke == 1">订单已退款</p>
												<p v-else>订单申请取消，审核中</p>
											</div>
											<div v-else>
												<div class="order_operatelast">

													<a href="javascript:void(0);"
														@click="orderdeliver(item.id, delivei, item)"
														v-for="(delivei, delivek) in item.menu" :key="delivek">{{
															delivei.title }}</a>

													<!-- <div
														v-if="item.is_pay == 1 && (item.pay_type == 1 || item.pay_type == 3 || item.pay_type == 4)">
														<a href="javascript:void(0);"
															v-if="item.apply_delete == 0 && (item.is_send == 0 || item.is_send == 2) && hasPerm(['order.send'])"
															@click="orderdeliver(item)">立即发货</a>
													</div> -->
													<!-- <a href="javascript:void(0);">退款</a> -->
													<!-- <a href="javascript:void(0);">关闭</a> -->
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="ordertabelistbuttom">
									<div class="ordertabelistbuttoms">
										<strong>收货信息：</strong>
										<p>
											<span>{{ item.receiver_name }}</span>
											<span>{{ item.receiver_mobile }}</span>
											<span>{{ item.address }}</span>
										</p>
										<a href="javascript:void(0);" class="orderxg"
											@click="reviseaddress(item, citylisted)"
											v-if="hasPerm(['order.update.address'])">编辑</a>
									</div>
									<!-- 暂时隐藏误删 -->
									<!-- <div class="ordertabelistbuttoms clearfloat">
										<div class="orderkded">
											<strong>物流信息：</strong>
											<p><span>申通快递</span></p>
										</div>
										<div class="orderkded">
											<strong>快递单号：</strong>
											<p><a href="javascript:void(0);">9979994605505</a></p>
										</div>
									</div> -->
								</div>
							</div>
						</div>
					</div>

					<div class="orderlastbut clearfloat">
						<div class="orderlastbutright">
							<el-pagination @size-change="handleSizeChange" hide-on-single-page
								@current-change="handleCurrentChange" :current-page.sync="currentPage"
								:page-sizes="[10, 20, 30, 40]" :page-size="eachPage"
								layout="total,sizes, prev, pager, next, jumper" :pager-count="5" :total="total_entry">
							</el-pagination>
						</div>
					</div>
				</div>
				<template v-else>
					<div class="zntj" v-if="datatype">
						<img class="img-default" src="../../assets/images/home-empty.png" alt="" />
						<p>暂无数据</p>
					</div>
				</template>
			</div>
		</div>
		<!-- 立即发货弹层 -->
		<orderdelivery ref="deliverybox" @delsuccess="delivesuccess" :isZc="ordertypes"></orderdelivery>

		<!-- 备注 -->
		<attribute ref="attributetc" @certitude="handleCertitude"></attribute>

		<!-- 修改地址弹层 -->
		<orderadress ref="addresstc" @adresscertitude="handleaddress"></orderadress>

		<!-- 确认签收弹窗 -->
		<el-dialog title="签收" :visible.sync="confirmQianshouShow" width="30%">
			<div class="confirm-qianshou">
				<el-radio v-model="confirmQianshou" label="1">妥投</el-radio>
				<el-radio v-model="confirmQianshou" label="2">拒收</el-radio>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="closeConfrimQiandao">取 消</el-button>
				<el-button type="primary" :loading="confirmQianshouLoading" @click="confrimQiandao">确 定</el-button>
			</div>
		</el-dialog>

		<!-- 日志弹层 -->
		<el-dialog title="操作日志" class="journaltc" :visible.sync="journaltype">
			<div class="journalbox">
				<div class="batchtable">
					<div class="batchtablecon">
						<table>
							<thead>
								<tr>
									<th class="tableleft">事件</th>
									<th class="tableleft">员工</th>
									<th>时间</th>
								</tr>
							</thead>
							<colgroup>
								<col style="width: 40%" />
								<col style="width: 30%" />
								<col style="width: 30%" />
							</colgroup>
							<tbody>
								<tr v-for="(item, index) in loglist" :key="index">
									<td class="tableleft">{{ item.req_route }}</td>
									<td class="tableleft">{{ item.opt_user }}</td>
									<td>{{ item.created_at }}</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="orderlastbut clearfloat" style="padding-bottom: 10px;">
						<div class="orderlastbutright">
							<el-pagination @size-change="logChange" hide-on-single-page @current-change="logCurrentChange"
								:current-page.sync="pages" :page-sizes="[10, 20, 30, 40]" :page-size="limit"
								layout="sizes, prev, pager, next, jumper" :pager-count="5" :total="logtotal">
							</el-pagination>
						</div>
					</div>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="journaltype = false">取 消</el-button>
				<el-button @click="journaltype = false" class="addressqr">确 定</el-button>
			</div>
		</el-dialog>

		<!-- 导出报表 -->
		<diy-export-more ref="diyExportMore" dialogExportKeys="order.export.field.list" dialogExportUrl="/order/export"
			:dialogExportIsSearch="true"></diy-export-more>
	</div>
</template>

<script>
export default {
	inject: ["reload"],
	data() {
		return {
			datatype: false,
			confirmQianshou: 0, // 确认签收
			confirmQianshouShow: false, // 确认签收弹窗
			confirmQianshouLoading: false, // 确认签收弹窗按钮loading
			ordertypes: false,
			valuesecond: "1", //收货人
			valuesecondlist: [{
				label: '收货人',
				value: '1',
			}, {
				label: '收货人电话',
				value: '2',
			},
				// {
				// 	label: '发票抬头',
				// 	value: '3',
				// }
			],
			valuename: '',
			servicestatus: "", //售后状态
			is_filter: false, //是否展开高级搜索
			orderstatus: "", //订单状态
			options: [{
				type: "",
				value: '1',
				label: '全部'
			}, {
				type: "",
				value: '2',
				label: 'PC商城'
			}, {
				type: "",
				value: '3',
				label: 'APP'
			}, {
				type: "",
				value: '4',
				label: '微信小程序'
			}, {
				type: "",
				value: '5',
				label: 'H5'
			}, {
				type: "secondary",
				value: '6',
				label: '政采订单'
			}, {
				type: "secondary",
				value: '7',
				label: '企业采购'
			}, {
				type: "secondary",
				value: '8',
				label: '积分福利'
			}],
			ordersecondary: false, //隐藏订单状态二级
			ordersecond: '', //二级显示菜单
			save: "", //省
			cname: "", //市
			city: [],
			bname: "", //区
			block: [],
			vipgrade: "", //会员等级

			orderref: "1", //订单编号和政采编号
			orderefName: "", //订单编号或政采编号名称
			shopnamesku: "1", //商品名称或SKU
			ordershopname: "", //商品名称或SKU搜索字段
			ordertype: "", //订单类型
			ordersource: "", //订单来源
			shoptype: "", //商品类型
			shipping: "", //配送方式
			valuetime: "id", //下单时间
			valuetimes: "", //日期选择
			ordertime: 'created_at', //头部时间搜索
			pickerOptions: {
				// 所有的截止时间为当天的23.59.59
				shortcuts: [{
					text: '今天',
					onClick(picker) {
						const start = new Date().setHours(0, 0, 0, 0);
						const end = new Date().setHours(23, 59, 59, 59);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '7天',
					onClick(picker) {
						const end = new Date().setHours(23, 59, 59, 59);
						const start = new Date();
						start.setTime(end - (3600 * 1000 * 24 * 7 - 1000));
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '30天',
					onClick(picker) {
						const end = new Date().setHours(23, 59, 59, 59);
						const start = new Date();
						start.setTime(end - (3600 * 1000 * 24 * 30 - 1000));
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			paytype: "", //支付方式
			ordersort: "id", //订单排序
			ordersortcon: 'desc', //正序倒序
			sortIndex: 2, //正序倒序的切换
			orderchecked: false, //是否选中订单列表
			currentPage: 1,
			eachPage: 10,
			total_entry: 0,
			journaltype: false, //控制日志是否显示
			ordersearchList: [{
				label: '订单编号',
				value: '1',
			},
				// {
				// 	label: '政采编号',
				// 	value: '2',
				// }
			],
			ordershopList: [{
				label: '商品名称',
				value: '1',
			},
				// {
				// 	label: '商品SKU',
				// 	value: '2',
				// }
			],
			timeList: [{
				label: '按下单时间',
				value: 'id',
			}, {
				label: '按支付时间',
				value: 'pay_time',
			}, {
				label: '按发货时间',
				value: 'send_time',
			}, {
				label: '按完成时间',
				value: 'confirm_time',
			}],
			shippingList: [{
				label: '快递发货',
				value: '0',
			}, {
				label: '自提',
				value: '1',
			}],
			activeName: 0,
			tableData: [],
			citylisted: [],
			loglist: [], //日志列表
			pages: 1,
			limit: 10,
			logtotal: 0,
			avticeOrderID: 0, // 当时处理
			logisticslist: [],//物流后更改
			tabstatus: 0,
			account_number: '',	//员工账号
			common_info: {}
		};
	},
	created() { },
	// 确保详情修改完成之后及时刷新页面
	activated() {

		let data = {
			keys: ["order.payment.type.map", "order.type.map", "order.status.list",
				"order.payment.type.list",
				"order.type.list", "order.source.type.list", "goods.vr_type.list", "user.level_list",
				"express.delivery.list", "order.source.type.map", "order.refund.status.list", "order.payment.pay_type.map", "order.payment.pay_type.list", "order.source.map", "order.source.list"
			]
		}
		this.common.getCommonInfo(this, data, res => {
			this.common_info = res;
		});

		setTimeout(() => {
			// 获取订单列表
			this.orderlistapi()
		}, 0);
	},
	mounted() {
		this.orderlistapi();
		this.citylistapi();
		this.logisticsapi();
	},
	methods: {

		// 提取搜索数据
		extractSearch() {
			let data = {
				search: [],
				order: [{
					key: this.ordersort,
					value: this.ordersortcon
				}],
				page: this.currentPage,
				limit: this.eachPage,
			};

			if (this.tabstatus > 0) {
				let obj = {
					"key": 'status',
					"value": this.tabstatus,
					"op": "equal"
				}
				data.search.push(obj);
			}
			if (this.orderefName) {
				let obj = {
					"key": this.orderref == 1 ? 'order_no' : "goods_name",
					"value": this.orderefName,
					"op": this.orderref == 1 ? 'search' : "search"
				}
				data.search.push(obj);
			}
			if (this.ordershopname) {
				let obj = {
					"key": this.shopnamesku == 1 ? 'goods_name' : "goods_sku",
					"value": this.ordershopname,
					"op": this.shopnamesku == 1 ? 'search' : "search"
				}
				data.search.push(obj);
			}
			if (this.ordersource) { //订单来源
				let obj = {
					"key": 'activity',
					"value": this.ordersource,
					"op": "equal"
				}
				data.search.push(obj);
				// data.activity_id=this.ordersource
			}
			if (this.ordertype) { //订单类型
				let obj = {
					"key": 'type',
					"value": this.ordertype,
					"op": "equal"
				}
				data.search.push(obj);
			}
			if (this.valuename) {
				let obj = {
					"key": this.valuesecond == 1 ? 'receiver_name' : this.valuesecond == 2 ? 'receiver_mobile' : this.valuesecond == 3 ? 'purchaser_organization_name' : '',
					"value": this.valuename,
					"op": 'search',
				}
				data.search.push(obj);
			}
			if (this.shoptype || this.shoptype === 0) { //商品类型
				let obj = {
					"key": 'vr_type',
					"value": this.shoptype,
					"op": "equal"
				}
				data.search.push(obj);
			}
			if (this.valuetimes) { //时间筛选
				let obj = {
					"key": this.ordertime,
					"value": [this.common.timeStampTurnTime(this.valuetimes[0]), this.common.timeStampTurnTime(this.valuetimes[1])],
					"op": "between"
				}
				data.search.push(obj);
			}
			if (this.servicestatus) {//售后状态
				let obj = {
					"key": "after_sale_status",
					"value": this.servicestatus,
					"op": "equal"
				}
				data.search.push(obj);
			}
			if (this.orderstatus) { //订单状态
				let obj = {
					"key": "status",
					"value": this.orderstatus,
					"op": "equal"
				}
				data.search.push(obj);
			}
			if (this.shipping) { //配送方式
				let obj = {
					"key": 'is_offline',
					"value": this.shipping,
					"op": "equal"
				}
				data.search.push(obj);
			}
			if (this.paytype) { //支付方式
				let obj = {
					"key": 'pay_type',
					"value": this.paytype,
					"op": "equal"
				}
				data.search.push(obj);
			}
			if (this.save) { //省
				let obj = {
					"key": 'province',
					"value": this.save,
					"op": "search"
				}
				data.search.push(obj);
			}
			if (this.cname) { //市
				let obj = {
					"key": 'city',
					"value": this.cname,
					"op": "search"
				}
				data.search.push(obj);
			}
			if (this.bname) { //区
				let obj = {
					"key": 'district',
					"value": this.bname,
					"op": "search"
				}
				data.search.push(obj);
			}
			if (this.vipgrade) { //会员等级
				let obj = {
					"key": 'level',
					"value": this.vipgrade,
					"op": "equal"
				}
				data.search.push(obj);
			}
			if (this.account_number) {
				let obj = {
					"key": "username",
					"value": this.account_number,
					"op": "search"
				}
				data.search.push(obj);
			}

			return data;
		},

		orderlistapi() {
			this.datatype = false;
			const data = this.extractSearch()
			let that = this;
			this.$get(this.$apis.orderList, data).then(res => {
				// //console.log(res)
				if (res.code == 200) {
					if (that.orderstatus) {
						that.tabstatus = Number(that.orderstatus);
						that.activeName = that.orderstatus;
					} else {
						// that.tabstatus=0;
						// that.activeName='0';
					}
					this.tableData = res.data.list;
					this.total_entry = res.data.total;
					this.datatype = true;
				} else {
					this.common.message(this, res.message);
				}
			}).catch(err => {
				// //console.log(err)
				this.common.message(this, err.message);
			});
		},

		addhandleChange(val) {
			this.ordersecondary = false;
			this.options.forEach((item, index) => {
				if (item.value == val) {
					if (item.type) {
						this.ordersecondary = true;
					}
				}
			})
		},
		// tab切换
		handleClick(tab) {
			this.currentPage = 1;
			this.tabstatus = tab.index;
			this.orderstatus = '';
			this.orderlistapi();
		},
		//头部搜索时间
		ordertimechange(val) {
			if (val == 'id') {
				val = 'created_at'
			}
			this.ordertime = val;
		},
		// 时间筛选
		timechange(val) {
			this.ordersort = val;
			this.orderlistapi();
		},
		ordersorted(index) {
			this.sortIndex = index;
			if (index == 1) {
				this.ordersortcon = 'asc'
			} else {
				this.ordersortcon = 'desc'
			}
			this.orderlistapi();
		},
		// 搜索
		ordersearched() {
			this.currentPage = 1;
			this.orderlistapi();
		},
		// 重置
		resetting() {
			this.orderefName = '';
			this.ordershopname = '';
			this.ordersource = '';
			this.ordertype = '';
			this.shoptype = '';
			this.valuetimes = '';
			this.orderstatus = '';
			this.shipping = '';
			this.servicestatus = '';
			this.paytype = '';
			this.vipgrade = '';
			this.valuename = '';
			this.save = '';
			this.cname = '';
			this.bname = '';
			this.reload();
			this.orderlistapi();
		},
		// 导出报表
		exportTable() {
			this.$refs.diyExportMore.exportMore = true
			// this.common.exportTable(this, '/order/export')
		},
		// 日志
		orderlog(id) {
			this.journaltype = true;
			this.orderedid = id;
			this.orderlogapi(this.orderedid);
		},
		logChange(val) {
			this.limit = val;
			this.orderlogapi(this.orderedid);
		},
		logCurrentChange(val) {
			this.pages = val;
			this.orderlogapi(this.orderedid);
		},
		orderlogapi(id) {
			let data = {
				page: this.pages,
				limit: this.limit,
			};
			this.$get(this.$apis.orderoperate + id, data).then(res => {
				// //console.log(res)
				if (res.code == 200) {
					this.loglist = res.data.list;
					this.logtotal = res.data.total;
				} else {
					this.common.message(this, res.message);
				}
			}).catch(err => {
				// //console.log(err)
				this.common.message(this, err.message);
			});
		},
		// 省市区
		citylistapi() {
			this.$get(this.$apis.cityList).then(res => {
				// //console.log(res)
				if (res.code == 200) {
					this.citylisted = res.data;
				} else {
					this.common.message(this, res.message);
				}
			}).catch(err => {
				// //console.log(err)
				this.common.message(this, err.message);
			});
		},

		choseProvince(e) {
			let that = this;
			that.city = [];
			that.block = [];
			that.cname = "";
			that.bname = "";
			for (var index2 in that.citylisted) {
				if (e === that.citylisted[index2].id) {
					that.shi1 = that.citylisted[index2].list;
					that.save = that.citylisted[index2].name;
					that.shi1.forEach(function(citem, cindex) {
						that.city.push({
							id: citem.id,
							value: citem.name,
							children: citem.list
						});
					});
				}
			}
		},

		// 选市
		choseCity(e) {
			let that = this;
			that.block = [];
			for (var index3 in that.city) {
				if (e === that.city[index3].id) {
					that.qu1 = that.city[index3].children;
					that.cname = that.city[index3].value;
					that.E = that.qu1[0].id;
					that.qu1.forEach(function(bitem, bindex) {
						that.block.push({
							id: bitem.id,
							value: bitem.name,
							children: []
						});
					});
				}
			}
		},
		// 选区
		choseBlock(e) {
			this.bname = e;
		},
		// 查看详情
		loopdetail(id) {
			this.$router.push({
				path: '/order/order_details',
				query: {
					id: id
				}
			});
		},
		// 备注
		attributed(val) {
			this.$refs.attributetc.open(val);
		},
		// 备注请求
		handleCertitude(val) {
			if (!val.words) {
				this.$message({
					type: 'warning',
					duration: 2000,
					message: '请填写备注信息',
				});
				return false;
			}
			let data = {
				remark: val.words
			};
			this.$put(this.$apis.orderattrbuite + val.id, data).then(res => {
				if (res.code == 200) {
					this.$message({
						type: 'success',
						duration: 1500,
						message: '添加备注成功',
					});
					this.$refs.attributetc.closeattrubute();
					this.orderlistapi();
				} else {
					this.common.message(this, res.message);
				}
			}).catch(err => {
				// //console.log(err)
				this.common.message(this, err.message);
			});
		},
		// 修改地址
		reviseaddress(val, city) {
			// //console.log(val)
			this.$refs.addresstc.open(val, city);
		},
		// 修改地址请求
		handleaddress(val) {

			let data = {
				name: val.receiver_name,  //收货人姓名
				mobile: val.receiver_mobile,  //收货人电话
				province: val.province,  //省份信息
				city: val.city,  //城市信息
				district: val.district,  //区县信息
				address: val.address,   //详细地址
			};
			this.$put(this.$apis.addressedit + val.id, data).then(res => {
				// //console.log(res)
				if (res.code == 200) {
					this.$message({
						type: 'success',
						duration: 1500,
						message: '修改成功',
						onClose: () => {
							this.$refs.addresstc.closeattrubute();
							this.orderlistapi();
						}
					});
				} else {
					this.common.message(this, res.message);
				}
			}).catch(err => {
				// //console.log(err)
				this.common.message(this, err.message);
			});
			// //console.log(val)
		},
		// 立即发货
		orderdeliver(id, item, order_item = {}) {
			// //console.log(order_item.order_source)
			this.avticeOrderID = id
			if (order_item.order_source > 4) {
				this.ordertypes = true
			} else {
				this.ordertypes = false
			}
			// 立即发货
			if (item.key.indexOf('order.send') != -1) {
				this.loopdeliveryapi(id, order_item.order_source);
			}
			// 目前注释 没有功能  有需要从政采  订单  拷贝
			// 确认签收
			if (item.key == 'sign.notice') {
				this.confirmQianshouShow = true;
			}
			// 确认支付
			if (item.key == 'pay.notice') {
				this.confirmPay(id)
			}
			// 订单取消
			if (item.key == 'cancel.notice') {
				this.$confirm("此操作将取消该订单, 是否继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).then(() => {
					this.$put(this.$apis.zycgordercancel + id).then(res => {
						if (res.code == 200) {
							this.$message.success(res.message)
							this.orderlistapi()
						} else {
							this.common.message(this, res.message);
						}
					}).catch(err => {
						this.common.message(this, err.message);
					})
				}).catch(() => {

				});
			}
		},
		confirmPay(id) {
			this.$put(this.$apis.zycgOrderConfirmPay + id).then(res => {
				//console.log(res, 'zycgOrderConfirmPay');
				if (res.code == 200) {
					this.$message.success(res.message)
					this.orderlistapi()
				} else {
					this.common.message(this, res.message);
				}
			}).catch(err => {
				this.common.message(this, err.message);
			})
		},
		// 取消设置签收弹窗
		closeConfrimQiandao() {
			this.confirmQianshou = 0
			this.confirmQianshouLoading = false
			this.confirmQianshouShow = false;
		},
		// 设置 签收
		confrimQiandao() {
			if (this.confirmQianshou == 0) {
				this.$message({
					message: '请选择签收方式',
					type: 'warning',
					offset: 200,
					duration: 1500,
				});
				return false
			}
			this.confirmQianshouLoading = true

			this.$put(this.$apis.zycgOrderQianshou + this.avticeOrderID, { sign_status: this.confirmQianshou }).then(res => {
				// //console.log(res, 'zycgOrderQianshou');
				if (res.code == 200) {
					this.$message.success(res.message)
					this.confirmQianshou = 0
					this.confirmQianshouShow = false
					this.orderlistapi()
				} else {
					this.common.message(this, res.message);
				}
				this.confirmQianshouLoading = false

			}).catch(error => {
				this.common.message(this, error.message);
			})

		},
		// 提交发货
		delivesuccess() {
			this.$message({
				message: '发货成功',
				type: 'success',
				offset: 200,
				duration: 1500,
			});
			this.$refs.deliverybox.closeattrubute();
			this.orderlistapi();
		},

		logisticsapi() {
			let data = {
				source: 4
			};
			this.$get(this.$apis.logistics, data).then(res => {
				if (res.code == 200) {
					this.logisticslist = res.data;
				} else {
					this.common.message(this, res.message);
				}
			}).catch(err => {
				// //console.log(err)
				this.common.message(this, err.message);
			});
		},
		loopdeliveryapi(id, order_source) {
			this.$get(this.$apis.loopdelivery + id).then(res => {
				if (res.code == 200) {
					res.data['order_source'] = order_source
					// if(order_source == 4){
					// 	// this.logisticsapi();
					// 	// //console.log(this.logisticslist)

					// }else{
					// 	this.$refs.deliverybox.open(res.data);
					// }
					let arr = res.data.order_detail.filter(item => Number(item.num) > Number(item.send_num));
					// console.log(arr)
					res.data.order_detail = arr;
					this.$refs.deliverybox.open(res.data, this.logisticslist);
				} else {
					this.common.message(this, res.message);
				}
			}).catch(err => {
				// //console.log(err)
				this.common.message(this, err.message);
			});
		},

		filtertab() {
			this.is_filter = !this.is_filter;
		},
		handleSizeChange(val) {
			this.eachPage = val;
			this.orderlistapi();
		},
		handleCurrentChange(val) {
			this.currentPage = val;
			this.common.backTop();
			this.orderlistapi();
		},
		delOrder(item) {
			let that = this;
			this.$confirm('确认是否要删除此订单', '提示', {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			}).then(res => {
				let data = { id: [] };
				data.id.push(item.id);
				that.$delete(that.$apis.orderDelete, data).then((result) => {
					if (result.code == 200) {
						that.$message({
							type: 'success',
							duration: 1500,
							message: '删除成功',
							onClose: () => {
								let index = that.tableData.findIndex(i => i.id == item.id);
								that.tableData.splice(index, 1)
							}
						});
					} else {
						that.common.message(that, result.message);
					}
				}).catch((error) => {
					that.common.message(that, error.message);
				})
			}).catch((err) => { })
		},

	},
};
</script>

<style scoped>
@import url("css/order.css");

.zntj {
	width: 100%;
	padding: 40px 0;
	box-sizing: border-box;
}

.zntj img {
	display: block;
	max-width: 180px;
	margin: 0 auto 10px;
}

.zntj p {
	font-size: 14px;
	color: #666;
	text-align: center;
}

.ordermanageboxbut {
	margin-top: 20px;
}

::v-deep .orderfirstleft .el-input__inner {
	height: 38px;
	line-height: 38px;
}

::v-deep .ordertimeinput .el-range-editor.el-input__inner {
	height: 38px;
}

.orderstatused p {
	text-align: center;
	font-size: 14px;
	color: #666;
}

.orderputong {
	display: inline-block;
	font-size: 14px;
	padding: 0 8px;
	box-sizing: border-box;
	line-height: 20px;
	border-radius: 2px;
	margin-right: 4px;
	background: #E9F0FF;
	color: #2F74F5;
}

.orderlastbutright {
	display: table;
	float: none;
	margin: 0 auto;
}

.ordertabelistright a {
	position: relative;
}

.ordertabelistright a i {
	display: block;
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background: red;
	position: absolute;
	top: 18px;
	right: -2px;
}</style>
